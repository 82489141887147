/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/futuredu-official/CDN@master/scripts/common/menu/init_variables.js
 * - /gh/futuredu-official/CDN@master/scripts/common/menu/menu_dropdown_initialization.js
 * - /gh/futuredu-official/CDN@master/scripts/common/menu/get_menu_header_20240206_0611.js
 * - /gh/futuredu-official/CDN@master/scripts/common/menu/Div_menu_20240213_2210.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
